var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
// HumanizeDuration.js - https://git.io/j0HgmQ

// @ts-check

/**
 * @typedef {string | ((unitCount: number) => string)} Unit
 */

/**
 * @typedef {("y" | "mo" | "w" | "d" | "h" | "m" | "s" | "ms")} UnitName
 */

/**
 * @typedef {Object} UnitMeasures
 * @prop {number} y
 * @prop {number} mo
 * @prop {number} w
 * @prop {number} d
 * @prop {number} h
 * @prop {number} m
 * @prop {number} s
 * @prop {number} ms
 */

/**
 * @typedef {Record<"0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9", string>} DigitReplacements
 */

/**
 * @typedef {Object} Language
 * @prop {Unit} y
 * @prop {Unit} mo
 * @prop {Unit} w
 * @prop {Unit} d
 * @prop {Unit} h
 * @prop {Unit} m
 * @prop {Unit} s
 * @prop {Unit} ms
 * @prop {string} [decimal]
 * @prop {string} [delimiter]
 * @prop {DigitReplacements} [_digitReplacements]
 * @prop {boolean} [_numberFirst]
 */

/**
 * @typedef {Object} Options
 * @prop {string} [language]
 * @prop {Record<string, Language>} [languages]
 * @prop {string[]} [fallbacks]
 * @prop {string} [delimiter]
 * @prop {string} [spacer]
 * @prop {boolean} [round]
 * @prop {number} [largest]
 * @prop {UnitName[]} [units]
 * @prop {string} [decimal]
 * @prop {string} [conjunction]
 * @prop {number} [maxDecimalPoints]
 * @prop {UnitMeasures} [unitMeasures]
 * @prop {boolean} [serialComma]
 * @prop {DigitReplacements} [digitReplacements]
 */

/**
 * @typedef {Required<Options>} NormalizedOptions
 */

(function () {
  // This has to be defined separately because of a bug: we want to alias
  // `gr` and `el` for backwards-compatiblity. In a breaking change, we can
  // remove `gr` entirely.
  // See https://github.com/EvanHahn/HumanizeDuration.js/issues/143 for more.
  var GREEK = language(function (c) {
    return c === 1 ? "\u03C7\u03C1\u03CC\u03BD\u03BF\u03C2" : "\u03C7\u03C1\u03CC\u03BD\u03B9\u03B1";
  }, function (c) {
    return c === 1 ? "\u03BC\u03AE\u03BD\u03B1\u03C2" : "\u03BC\u03AE\u03BD\u03B5\u03C2";
  }, function (c) {
    return c === 1 ? "\u03B5\u03B2\u03B4\u03BF\u03BC\u03AC\u03B4\u03B1" : "\u03B5\u03B2\u03B4\u03BF\u03BC\u03AC\u03B4\u03B5\u03C2";
  }, function (c) {
    return c === 1 ? "\u03BC\u03AD\u03C1\u03B1" : "\u03BC\u03AD\u03C1\u03B5\u03C2";
  }, function (c) {
    return c === 1 ? "\u03CE\u03C1\u03B1" : "\u03CE\u03C1\u03B5\u03C2";
  }, function (c) {
    return c === 1 ? "\u03BB\u03B5\u03C0\u03C4\u03CC" : "\u03BB\u03B5\u03C0\u03C4\u03AC";
  }, function (c) {
    return c === 1 ? "\u03B4\u03B5\u03C5\u03C4\u03B5\u03C1\u03CC\u03BB\u03B5\u03C0\u03C4\u03BF" : "\u03B4\u03B5\u03C5\u03C4\u03B5\u03C1\u03CC\u03BB\u03B5\u03C0\u03C4\u03B1";
  }, function (c) {
    return (c === 1 ? "\u03C7\u03B9\u03BB\u03B9\u03BF\u03C3\u03C4\u03CC" : "\u03C7\u03B9\u03BB\u03B9\u03BF\u03C3\u03C4\u03AC") + " \u03C4\u03BF\u03C5 \u03B4\u03B5\u03C5\u03C4\u03B5\u03C1\u03BF\u03BB\u03AD\u03C0\u03C4\u03BF\u03C5";
  }, ",");

  /** @type {Record<string, Language>} */
  var LANGUAGES = {
    af: language("jaar", function (c) {
      return "maand" + (c === 1 ? "" : "e");
    }, function (c) {
      return c === 1 ? "week" : "weke";
    }, function (c) {
      return c === 1 ? "dag" : "dae";
    }, function (c) {
      return c === 1 ? "uur" : "ure";
    }, function (c) {
      return c === 1 ? "minuut" : "minute";
    }, function (c) {
      return "sekonde" + (c === 1 ? "" : "s");
    }, function (c) {
      return "millisekonde" + (c === 1 ? "" : "s");
    }, ","),
    ar: assign(language(function (c) {
      return ["\u0633\u0646\u0629", "\u0633\u0646\u062A\u0627\u0646", "\u0633\u0646\u0648\u0627\u062A"][getArabicForm(c)];
    }, function (c) {
      return ["\u0634\u0647\u0631", "\u0634\u0647\u0631\u0627\u0646", "\u0623\u0634\u0647\u0631"][getArabicForm(c)];
    }, function (c) {
      return ["\u0623\u0633\u0628\u0648\u0639", "\u0623\u0633\u0628\u0648\u0639\u064A\u0646", "\u0623\u0633\u0627\u0628\u064A\u0639"][getArabicForm(c)];
    }, function (c) {
      return ["\u064A\u0648\u0645", "\u064A\u0648\u0645\u064A\u0646", "\u0623\u064A\u0627\u0645"][getArabicForm(c)];
    }, function (c) {
      return ["\u0633\u0627\u0639\u0629", "\u0633\u0627\u0639\u062A\u064A\u0646", "\u0633\u0627\u0639\u0627\u062A"][getArabicForm(c)];
    }, function (c) {
      return ["\u062F\u0642\u064A\u0642\u0629", "\u062F\u0642\u064A\u0642\u062A\u0627\u0646", "\u062F\u0642\u0627\u0626\u0642"][getArabicForm(c)];
    }, function (c) {
      return ["\u062B\u0627\u0646\u064A\u0629", "\u062B\u0627\u0646\u064A\u062A\u0627\u0646", "\u062B\u0648\u0627\u0646\u064A"][getArabicForm(c)];
    }, function (c) {
      return ["\u062C\u0632\u0621 \u0645\u0646 \u0627\u0644\u062B\u0627\u0646\u064A\u0629", "\u062C\u0632\u0622\u0646 \u0645\u0646 \u0627\u0644\u062B\u0627\u0646\u064A\u0629", "\u0623\u062C\u0632\u0627\u0621 \u0645\u0646 \u0627\u0644\u062B\u0627\u0646\u064A\u0629"][getArabicForm(c)];
    }, ","), {
      delimiter: " \uFEED ",
      _digitReplacements: ["\u06F0", "\u0661", "\u0662", "\u0663", "\u0664", "\u0665", "\u0666", "\u0667", "\u0668", "\u0669"]
    }),
    bg: language(function (c) {
      return ["\u0433\u043E\u0434\u0438\u043D\u0438", "\u0433\u043E\u0434\u0438\u043D\u0430", "\u0433\u043E\u0434\u0438\u043D\u0438"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043C\u0435\u0441\u0435\u0446\u0430", "\u043C\u0435\u0441\u0435\u0446", "\u043C\u0435\u0441\u0435\u0446\u0430"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0441\u0435\u0434\u043C\u0438\u0446\u0438", "\u0441\u0435\u0434\u043C\u0438\u0446\u0430", "\u0441\u0435\u0434\u043C\u0438\u0446\u0438"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0434\u043D\u0438", "\u0434\u0435\u043D", "\u0434\u043D\u0438"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0447\u0430\u0441\u0430", "\u0447\u0430\u0441", "\u0447\u0430\u0441\u0430"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043C\u0438\u043D\u0443\u0442\u0438", "\u043C\u0438\u043D\u0443\u0442\u0430", "\u043C\u0438\u043D\u0443\u0442\u0438"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0441\u0435\u043A\u0443\u043D\u0434\u0438", "\u0441\u0435\u043A\u0443\u043D\u0434\u0430", "\u0441\u0435\u043A\u0443\u043D\u0434\u0438"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043C\u0438\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434\u0438", "\u043C\u0438\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434\u0430", "\u043C\u0438\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434\u0438"][getSlavicForm(c)];
    }, ","),
    bn: language("\u09AC\u099B\u09B0", "\u09AE\u09BE\u09B8", "\u09B8\u09AA\u09CD\u09A4\u09BE\u09B9", "\u09A6\u09BF\u09A8", "\u0998\u09A8\u09CD\u099F\u09BE", "\u09AE\u09BF\u09A8\u09BF\u099F", "\u09B8\u09C7\u0995\u09C7\u09A8\u09CD\u09A1", "\u09AE\u09BF\u09B2\u09BF\u09B8\u09C7\u0995\u09C7\u09A8\u09CD\u09A1"),
    ca: language(function (c) {
      return "any" + (c === 1 ? "" : "s");
    }, function (c) {
      return "mes" + (c === 1 ? "" : "os");
    }, function (c) {
      return "setman" + (c === 1 ? "a" : "es");
    }, function (c) {
      return "di" + (c === 1 ? "a" : "es");
    }, function (c) {
      return "hor" + (c === 1 ? "a" : "es");
    }, function (c) {
      return "minut" + (c === 1 ? "" : "s");
    }, function (c) {
      return "segon" + (c === 1 ? "" : "s");
    }, function (c) {
      return "milisegon" + (c === 1 ? "" : "s");
    }, ","),
    cs: language(function (c) {
      return ["rok", "roku", "roky", "let"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["m\u011Bs\xEDc", "m\u011Bs\xEDce", "m\u011Bs\xEDce", "m\u011Bs\xEDc\u016F"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["t\xFDden", "t\xFDdne", "t\xFDdny", "t\xFDdn\u016F"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["den", "dne", "dny", "dn\xED"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["hodina", "hodiny", "hodiny", "hodin"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["minuta", "minuty", "minuty", "minut"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["sekunda", "sekundy", "sekundy", "sekund"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["milisekunda", "milisekundy", "milisekundy", "milisekund"][getCzechOrSlovakForm(c)];
    }, ","),
    cy: language("flwyddyn", "mis", "wythnos", "diwrnod", "awr", "munud", "eiliad", "milieiliad"),
    da: language("\xE5r", function (c) {
      return "m\xE5ned" + (c === 1 ? "" : "er");
    }, function (c) {
      return "uge" + (c === 1 ? "" : "r");
    }, function (c) {
      return "dag" + (c === 1 ? "" : "e");
    }, function (c) {
      return "time" + (c === 1 ? "" : "r");
    }, function (c) {
      return "minut" + (c === 1 ? "" : "ter");
    }, function (c) {
      return "sekund" + (c === 1 ? "" : "er");
    }, function (c) {
      return "millisekund" + (c === 1 ? "" : "er");
    }, ","),
    de: language(function (c) {
      return "Jahr" + (c === 1 ? "" : "e");
    }, function (c) {
      return "Monat" + (c === 1 ? "" : "e");
    }, function (c) {
      return "Woche" + (c === 1 ? "" : "n");
    }, function (c) {
      return "Tag" + (c === 1 ? "" : "e");
    }, function (c) {
      return "Stunde" + (c === 1 ? "" : "n");
    }, function (c) {
      return "Minute" + (c === 1 ? "" : "n");
    }, function (c) {
      return "Sekunde" + (c === 1 ? "" : "n");
    }, function (c) {
      return "Millisekunde" + (c === 1 ? "" : "n");
    }, ","),
    el: GREEK,
    en: language(function (c) {
      return "year" + (c === 1 ? "" : "s");
    }, function (c) {
      return "month" + (c === 1 ? "" : "s");
    }, function (c) {
      return "week" + (c === 1 ? "" : "s");
    }, function (c) {
      return "day" + (c === 1 ? "" : "s");
    }, function (c) {
      return "hour" + (c === 1 ? "" : "s");
    }, function (c) {
      return "minute" + (c === 1 ? "" : "s");
    }, function (c) {
      return "second" + (c === 1 ? "" : "s");
    }, function (c) {
      return "millisecond" + (c === 1 ? "" : "s");
    }),
    eo: language(function (c) {
      return "jaro" + (c === 1 ? "" : "j");
    }, function (c) {
      return "monato" + (c === 1 ? "" : "j");
    }, function (c) {
      return "semajno" + (c === 1 ? "" : "j");
    }, function (c) {
      return "tago" + (c === 1 ? "" : "j");
    }, function (c) {
      return "horo" + (c === 1 ? "" : "j");
    }, function (c) {
      return "minuto" + (c === 1 ? "" : "j");
    }, function (c) {
      return "sekundo" + (c === 1 ? "" : "j");
    }, function (c) {
      return "milisekundo" + (c === 1 ? "" : "j");
    }, ","),
    es: language(function (c) {
      return "a\xF1o" + (c === 1 ? "" : "s");
    }, function (c) {
      return "mes" + (c === 1 ? "" : "es");
    }, function (c) {
      return "semana" + (c === 1 ? "" : "s");
    }, function (c) {
      return "d\xEDa" + (c === 1 ? "" : "s");
    }, function (c) {
      return "hora" + (c === 1 ? "" : "s");
    }, function (c) {
      return "minuto" + (c === 1 ? "" : "s");
    }, function (c) {
      return "segundo" + (c === 1 ? "" : "s");
    }, function (c) {
      return "milisegundo" + (c === 1 ? "" : "s");
    }, ","),
    et: language(function (c) {
      return "aasta" + (c === 1 ? "" : "t");
    }, function (c) {
      return "kuu" + (c === 1 ? "" : "d");
    }, function (c) {
      return "n\xE4dal" + (c === 1 ? "" : "at");
    }, function (c) {
      return "p\xE4ev" + (c === 1 ? "" : "a");
    }, function (c) {
      return "tund" + (c === 1 ? "" : "i");
    }, function (c) {
      return "minut" + (c === 1 ? "" : "it");
    }, function (c) {
      return "sekund" + (c === 1 ? "" : "it");
    }, function (c) {
      return "millisekund" + (c === 1 ? "" : "it");
    }, ","),
    eu: language("urte", "hilabete", "aste", "egun", "ordu", "minutu", "segundo", "milisegundo", ","),
    fa: language("\u0633\u0627\u0644", "\u0645\u0627\u0647", "\u0647\u0641\u062A\u0647", "\u0631\u0648\u0632", "\u0633\u0627\u0639\u062A", "\u062F\u0642\u06CC\u0642\u0647", "\u062B\u0627\u0646\u06CC\u0647", "\u0645\u06CC\u0644\u06CC \u062B\u0627\u0646\u06CC\u0647"),
    fi: language(function (c) {
      return c === 1 ? "vuosi" : "vuotta";
    }, function (c) {
      return c === 1 ? "kuukausi" : "kuukautta";
    }, function (c) {
      return "viikko" + (c === 1 ? "" : "a");
    }, function (c) {
      return "p\xE4iv\xE4" + (c === 1 ? "" : "\xE4");
    }, function (c) {
      return "tunti" + (c === 1 ? "" : "a");
    }, function (c) {
      return "minuutti" + (c === 1 ? "" : "a");
    }, function (c) {
      return "sekunti" + (c === 1 ? "" : "a");
    }, function (c) {
      return "millisekunti" + (c === 1 ? "" : "a");
    }, ","),
    fo: language("\xE1r", function (c) {
      return c === 1 ? "m\xE1na\xF0ur" : "m\xE1na\xF0ir";
    }, function (c) {
      return c === 1 ? "vika" : "vikur";
    }, function (c) {
      return c === 1 ? "dagur" : "dagar";
    }, function (c) {
      return c === 1 ? "t\xEDmi" : "t\xEDmar";
    }, function (c) {
      return c === 1 ? "minuttur" : "minuttir";
    }, "sekund", "millisekund", ","),
    fr: language(function (c) {
      return "an" + (c >= 2 ? "s" : "");
    }, "mois", function (c) {
      return "semaine" + (c >= 2 ? "s" : "");
    }, function (c) {
      return "jour" + (c >= 2 ? "s" : "");
    }, function (c) {
      return "heure" + (c >= 2 ? "s" : "");
    }, function (c) {
      return "minute" + (c >= 2 ? "s" : "");
    }, function (c) {
      return "seconde" + (c >= 2 ? "s" : "");
    }, function (c) {
      return "milliseconde" + (c >= 2 ? "s" : "");
    }, ","),
    gr: GREEK,
    he: language(function (c) {
      return c === 1 ? "\u05E9\u05E0\u05D4" : "\u05E9\u05E0\u05D9\u05DD";
    }, function (c) {
      return c === 1 ? "\u05D7\u05D5\u05D3\u05E9" : "\u05D7\u05D5\u05D3\u05E9\u05D9\u05DD";
    }, function (c) {
      return c === 1 ? "\u05E9\u05D1\u05D5\u05E2" : "\u05E9\u05D1\u05D5\u05E2\u05D5\u05EA";
    }, function (c) {
      return c === 1 ? "\u05D9\u05D5\u05DD" : "\u05D9\u05DE\u05D9\u05DD";
    }, function (c) {
      return c === 1 ? "\u05E9\u05E2\u05D4" : "\u05E9\u05E2\u05D5\u05EA";
    }, function (c) {
      return c === 1 ? "\u05D3\u05E7\u05D4" : "\u05D3\u05E7\u05D5\u05EA";
    }, function (c) {
      return c === 1 ? "\u05E9\u05E0\u05D9\u05D4" : "\u05E9\u05E0\u05D9\u05D5\u05EA";
    }, function (c) {
      return c === 1 ? "\u05DE\u05D9\u05DC\u05D9\u05E9\u05E0\u05D9\u05D9\u05D4" : "\u05DE\u05D9\u05DC\u05D9\u05E9\u05E0\u05D9\u05D5\u05EA";
    }),
    hr: language(function (c) {
      if (c % 10 === 2 || c % 10 === 3 || c % 10 === 4) {
        return "godine";
      }
      return "godina";
    }, function (c) {
      if (c === 1) {
        return "mjesec";
      } else if (c === 2 || c === 3 || c === 4) {
        return "mjeseca";
      }
      return "mjeseci";
    }, function (c) {
      if (c % 10 === 1 && c !== 11) {
        return "tjedan";
      }
      return "tjedna";
    }, function (c) {
      return c === 1 ? "dan" : "dana";
    }, function (c) {
      if (c === 1) {
        return "sat";
      } else if (c === 2 || c === 3 || c === 4) {
        return "sata";
      }
      return "sati";
    }, function (c) {
      var mod10 = c % 10;
      if ((mod10 === 2 || mod10 === 3 || mod10 === 4) && (c < 10 || c > 14)) {
        return "minute";
      }
      return "minuta";
    }, function (c) {
      var mod10 = c % 10;
      if (mod10 === 5 || Math.floor(c) === c && c >= 10 && c <= 19) {
        return "sekundi";
      } else if (mod10 === 1) {
        return "sekunda";
      } else if (mod10 === 2 || mod10 === 3 || mod10 === 4) {
        return "sekunde";
      }
      return "sekundi";
    }, function (c) {
      if (c === 1) {
        return "milisekunda";
      } else if (c % 10 === 2 || c % 10 === 3 || c % 10 === 4) {
        return "milisekunde";
      }
      return "milisekundi";
    }, ","),
    hi: language("\u0938\u093E\u0932", function (c) {
      return c === 1 ? "\u092E\u0939\u0940\u0928\u093E" : "\u092E\u0939\u0940\u0928\u0947";
    }, function (c) {
      return c === 1 ? "\u0939\u095E\u094D\u0924\u093E" : "\u0939\u092B\u094D\u0924\u0947";
    }, "\u0926\u093F\u0928", function (c) {
      return c === 1 ? "\u0918\u0902\u091F\u093E" : "\u0918\u0902\u091F\u0947";
    }, "\u092E\u093F\u0928\u091F", "\u0938\u0947\u0915\u0902\u0921", "\u092E\u093F\u0932\u0940\u0938\u0947\u0915\u0902\u0921"),
    hu: language("\xE9v", "h\xF3nap", "h\xE9t", "nap", "\xF3ra", "perc", "m\xE1sodperc", "ezredm\xE1sodperc", ","),
    id: language("tahun", "bulan", "minggu", "hari", "jam", "menit", "detik", "milidetik"),
    is: language("\xE1r", function (c) {
      return "m\xE1nu\xF0" + (c === 1 ? "ur" : "ir");
    }, function (c) {
      return "vik" + (c === 1 ? "a" : "ur");
    }, function (c) {
      return "dag" + (c === 1 ? "ur" : "ar");
    }, function (c) {
      return "klukkut\xEDm" + (c === 1 ? "i" : "ar");
    }, function (c) {
      return "m\xEDn\xFAt" + (c === 1 ? "a" : "ur");
    }, function (c) {
      return "sek\xFAnd" + (c === 1 ? "a" : "ur");
    }, function (c) {
      return "millisek\xFAnd" + (c === 1 ? "a" : "ur");
    }),
    it: language(function (c) {
      return "ann" + (c === 1 ? "o" : "i");
    }, function (c) {
      return "mes" + (c === 1 ? "e" : "i");
    }, function (c) {
      return "settiman" + (c === 1 ? "a" : "e");
    }, function (c) {
      return "giorn" + (c === 1 ? "o" : "i");
    }, function (c) {
      return "or" + (c === 1 ? "a" : "e");
    }, function (c) {
      return "minut" + (c === 1 ? "o" : "i");
    }, function (c) {
      return "second" + (c === 1 ? "o" : "i");
    }, function (c) {
      return "millisecond" + (c === 1 ? "o" : "i");
    }, ","),
    ja: language("\u5E74", "\u30F6\u6708", "\u9031", "\u65E5", "\u6642\u9593", "\u5206", "\u79D2", "\u30DF\u30EA\u79D2"),
    km: language("\u1786\u17D2\u1793\u17B6\u17C6", "\u1781\u17C2", "\u179F\u1794\u17D2\u178F\u17B6\u17A0\u17CD", "\u1790\u17D2\u1784\u17C3", "\u1798\u17C9\u17C4\u1784", "\u1793\u17B6\u1791\u17B8", "\u179C\u17B7\u1793\u17B6\u1791\u17B8", "\u1798\u17B7\u179B\u17D2\u179B\u17B8\u179C\u17B7\u1793\u17B6\u1791\u17B8"),
    kn: language(function (c) {
      return c === 1 ? "\u0CB5\u0CB0\u0CCD\u0CB7" : "\u0CB5\u0CB0\u0CCD\u0CB7\u0C97\u0CB3\u0CC1";
    }, function (c) {
      return c === 1 ? "\u0CA4\u0CBF\u0C82\u0C97\u0CB3\u0CC1" : "\u0CA4\u0CBF\u0C82\u0C97\u0CB3\u0CC1\u0C97\u0CB3\u0CC1";
    }, function (c) {
      return c === 1 ? "\u0CB5\u0CBE\u0CB0" : "\u0CB5\u0CBE\u0CB0\u0C97\u0CB3\u0CC1";
    }, function (c) {
      return c === 1 ? "\u0CA6\u0CBF\u0CA8" : "\u0CA6\u0CBF\u0CA8\u0C97\u0CB3\u0CC1";
    }, function (c) {
      return c === 1 ? "\u0C97\u0C82\u0C9F\u0CC6" : "\u0C97\u0C82\u0C9F\u0CC6\u0C97\u0CB3\u0CC1";
    }, function (c) {
      return c === 1 ? "\u0CA8\u0CBF\u0CAE\u0CBF\u0CB7" : "\u0CA8\u0CBF\u0CAE\u0CBF\u0CB7\u0C97\u0CB3\u0CC1";
    }, function (c) {
      return c === 1 ? "\u0CB8\u0CC6\u0C95\u0CC6\u0C82\u0CA1\u0CCD" : "\u0CB8\u0CC6\u0C95\u0CC6\u0C82\u0CA1\u0CC1\u0C97\u0CB3\u0CC1";
    }, function (c) {
      return c === 1 ? "\u0CAE\u0CBF\u0CB2\u0CBF\u0CB8\u0CC6\u0C95\u0CC6\u0C82\u0CA1\u0CCD" : "\u0CAE\u0CBF\u0CB2\u0CBF\u0CB8\u0CC6\u0C95\u0CC6\u0C82\u0CA1\u0CC1\u0C97\u0CB3\u0CC1";
    }),
    ko: language("\uB144", "\uAC1C\uC6D4", "\uC8FC\uC77C", "\uC77C", "\uC2DC\uAC04", "\uBD84", "\uCD08", "\uBC00\uB9AC \uCD08"),
    ku: language("sal", "meh", "hefte", "roj", "seet", "deqe", "saniye", "m\xEEl\xEE\xE7irk", ","),
    lo: language("\u0E9B\u0EB5", "\u0EC0\u0E94\u0EB7\u0EAD\u0E99", "\u0EAD\u0EB2\u0E97\u0EB4\u0E94", "\u0EA1\u0EB7\u0EC9", "\u0E8A\u0EBB\u0EC8\u0EA7\u0EC2\u0EA1\u0E87", "\u0E99\u0EB2\u0E97\u0EB5", "\u0EA7\u0EB4\u0E99\u0EB2\u0E97\u0EB5", "\u0EA1\u0EB4\u0E99\u0EA5\u0EB4\u0EA7\u0EB4\u0E99\u0EB2\u0E97\u0EB5", ","),
    lt: language(function (c) {
      return c % 10 === 0 || c % 100 >= 10 && c % 100 <= 20 ? "met\u0173" : "metai";
    }, function (c) {
      return ["m\u0117nuo", "m\u0117nesiai", "m\u0117nesi\u0173"][getLithuanianForm(c)];
    }, function (c) {
      return ["savait\u0117", "savait\u0117s", "savai\u010Di\u0173"][getLithuanianForm(c)];
    }, function (c) {
      return ["diena", "dienos", "dien\u0173"][getLithuanianForm(c)];
    }, function (c) {
      return ["valanda", "valandos", "valand\u0173"][getLithuanianForm(c)];
    }, function (c) {
      return ["minut\u0117", "minut\u0117s", "minu\u010Di\u0173"][getLithuanianForm(c)];
    }, function (c) {
      return ["sekund\u0117", "sekund\u0117s", "sekund\u017Ei\u0173"][getLithuanianForm(c)];
    }, function (c) {
      return ["milisekund\u0117", "milisekund\u0117s", "milisekund\u017Ei\u0173"][getLithuanianForm(c)];
    }, ","),
    lv: language(function (c) {
      return getLatvianForm(c) ? "gads" : "gadi";
    }, function (c) {
      return getLatvianForm(c) ? "m\u0113nesis" : "m\u0113ne\u0161i";
    }, function (c) {
      return getLatvianForm(c) ? "ned\u0113\u013Ca" : "ned\u0113\u013Cas";
    }, function (c) {
      return getLatvianForm(c) ? "diena" : "dienas";
    }, function (c) {
      return getLatvianForm(c) ? "stunda" : "stundas";
    }, function (c) {
      return getLatvianForm(c) ? "min\u016Bte" : "min\u016Btes";
    }, function (c) {
      return getLatvianForm(c) ? "sekunde" : "sekundes";
    }, function (c) {
      return getLatvianForm(c) ? "milisekunde" : "milisekundes";
    }, ","),
    mk: language(function (c) {
      return c === 1 ? "\u0433\u043E\u0434\u0438\u043D\u0430" : "\u0433\u043E\u0434\u0438\u043D\u0438";
    }, function (c) {
      return c === 1 ? "\u043C\u0435\u0441\u0435\u0446" : "\u043C\u0435\u0441\u0435\u0446\u0438";
    }, function (c) {
      return c === 1 ? "\u043D\u0435\u0434\u0435\u043B\u0430" : "\u043D\u0435\u0434\u0435\u043B\u0438";
    }, function (c) {
      return c === 1 ? "\u0434\u0435\u043D" : "\u0434\u0435\u043D\u0430";
    }, function (c) {
      return c === 1 ? "\u0447\u0430\u0441" : "\u0447\u0430\u0441\u0430";
    }, function (c) {
      return c === 1 ? "\u043C\u0438\u043D\u0443\u0442\u0430" : "\u043C\u0438\u043D\u0443\u0442\u0438";
    }, function (c) {
      return c === 1 ? "\u0441\u0435\u043A\u0443\u043D\u0434\u0430" : "\u0441\u0435\u043A\u0443\u043D\u0434\u0438";
    }, function (c) {
      return c === 1 ? "\u043C\u0438\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434\u0430" : "\u043C\u0438\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434\u0438";
    }, ","),
    mn: language("\u0436\u0438\u043B", "\u0441\u0430\u0440", "\u0434\u043E\u043B\u043E\u043E \u0445\u043E\u043D\u043E\u0433", "\u04E9\u0434\u04E9\u0440", "\u0446\u0430\u0433", "\u043C\u0438\u043D\u0443\u0442", "\u0441\u0435\u043A\u0443\u043D\u0434", "\u043C\u0438\u043B\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434"),
    mr: language(function (c) {
      return c === 1 ? "\u0935\u0930\u094D\u0937" : "\u0935\u0930\u094D\u0937\u0947";
    }, function (c) {
      return c === 1 ? "\u092E\u0939\u093F\u0928\u093E" : "\u092E\u0939\u093F\u0928\u0947";
    }, function (c) {
      return c === 1 ? "\u0906\u0920\u0935\u0921\u093E" : "\u0906\u0920\u0935\u0921\u0947";
    }, "\u0926\u093F\u0935\u0938", "\u0924\u093E\u0938", function (c) {
      return c === 1 ? "\u092E\u093F\u0928\u093F\u091F" : "\u092E\u093F\u0928\u093F\u091F\u0947";
    }, "\u0938\u0947\u0915\u0902\u0926", "\u092E\u093F\u0932\u093F\u0938\u0947\u0915\u0902\u0926"),
    ms: language("tahun", "bulan", "minggu", "hari", "jam", "minit", "saat", "milisaat"),
    nl: language("jaar", function (c) {
      return c === 1 ? "maand" : "maanden";
    }, function (c) {
      return c === 1 ? "week" : "weken";
    }, function (c) {
      return c === 1 ? "dag" : "dagen";
    }, "uur", function (c) {
      return c === 1 ? "minuut" : "minuten";
    }, function (c) {
      return c === 1 ? "seconde" : "seconden";
    }, function (c) {
      return c === 1 ? "milliseconde" : "milliseconden";
    }, ","),
    no: language("\xE5r", function (c) {
      return "m\xE5ned" + (c === 1 ? "" : "er");
    }, function (c) {
      return "uke" + (c === 1 ? "" : "r");
    }, function (c) {
      return "dag" + (c === 1 ? "" : "er");
    }, function (c) {
      return "time" + (c === 1 ? "" : "r");
    }, function (c) {
      return "minutt" + (c === 1 ? "" : "er");
    }, function (c) {
      return "sekund" + (c === 1 ? "" : "er");
    }, function (c) {
      return "millisekund" + (c === 1 ? "" : "er");
    }, ","),
    pl: language(function (c) {
      return ["rok", "roku", "lata", "lat"][getPolishForm(c)];
    }, function (c) {
      return ["miesi\u0105c", "miesi\u0105ca", "miesi\u0105ce", "miesi\u0119cy"][getPolishForm(c)];
    }, function (c) {
      return ["tydzie\u0144", "tygodnia", "tygodnie", "tygodni"][getPolishForm(c)];
    }, function (c) {
      return ["dzie\u0144", "dnia", "dni", "dni"][getPolishForm(c)];
    }, function (c) {
      return ["godzina", "godziny", "godziny", "godzin"][getPolishForm(c)];
    }, function (c) {
      return ["minuta", "minuty", "minuty", "minut"][getPolishForm(c)];
    }, function (c) {
      return ["sekunda", "sekundy", "sekundy", "sekund"][getPolishForm(c)];
    }, function (c) {
      return ["milisekunda", "milisekundy", "milisekundy", "milisekund"][getPolishForm(c)];
    }, ","),
    pt: language(function (c) {
      return "ano" + (c === 1 ? "" : "s");
    }, function (c) {
      return c === 1 ? "m\xEAs" : "meses";
    }, function (c) {
      return "semana" + (c === 1 ? "" : "s");
    }, function (c) {
      return "dia" + (c === 1 ? "" : "s");
    }, function (c) {
      return "hora" + (c === 1 ? "" : "s");
    }, function (c) {
      return "minuto" + (c === 1 ? "" : "s");
    }, function (c) {
      return "segundo" + (c === 1 ? "" : "s");
    }, function (c) {
      return "milissegundo" + (c === 1 ? "" : "s");
    }, ","),
    ro: language(function (c) {
      return c === 1 ? "an" : "ani";
    }, function (c) {
      return c === 1 ? "lun\u0103" : "luni";
    }, function (c) {
      return c === 1 ? "s\u0103pt\u0103m\xE2n\u0103" : "s\u0103pt\u0103m\xE2ni";
    }, function (c) {
      return c === 1 ? "zi" : "zile";
    }, function (c) {
      return c === 1 ? "or\u0103" : "ore";
    }, function (c) {
      return c === 1 ? "minut" : "minute";
    }, function (c) {
      return c === 1 ? "secund\u0103" : "secunde";
    }, function (c) {
      return c === 1 ? "milisecund\u0103" : "milisecunde";
    }, ","),
    ru: language(function (c) {
      return ["\u043B\u0435\u0442", "\u0433\u043E\u0434", "\u0433\u043E\u0434\u0430"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043C\u0435\u0441\u044F\u0446\u0435\u0432", "\u043C\u0435\u0441\u044F\u0446", "\u043C\u0435\u0441\u044F\u0446\u0430"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043D\u0435\u0434\u0435\u043B\u044C", "\u043D\u0435\u0434\u0435\u043B\u044F", "\u043D\u0435\u0434\u0435\u043B\u0438"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0434\u043D\u0435\u0439", "\u0434\u0435\u043D\u044C", "\u0434\u043D\u044F"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0447\u0430\u0441\u043E\u0432", "\u0447\u0430\u0441", "\u0447\u0430\u0441\u0430"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043C\u0438\u043D\u0443\u0442", "\u043C\u0438\u043D\u0443\u0442\u0430", "\u043C\u0438\u043D\u0443\u0442\u044B"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0441\u0435\u043A\u0443\u043D\u0434", "\u0441\u0435\u043A\u0443\u043D\u0434\u0430", "\u0441\u0435\u043A\u0443\u043D\u0434\u044B"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043C\u0438\u043B\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434", "\u043C\u0438\u043B\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434\u0430", "\u043C\u0438\u043B\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434\u044B"][getSlavicForm(c)];
    }, ","),
    sq: language(function (c) {
      return c === 1 ? "vit" : "vjet";
    }, "muaj", "jav\xEB", "dit\xEB", "or\xEB", function (c) {
      return "minut" + (c === 1 ? "\xEB" : "a");
    }, function (c) {
      return "sekond" + (c === 1 ? "\xEB" : "a");
    }, function (c) {
      return "milisekond" + (c === 1 ? "\xEB" : "a");
    }, ","),
    sr: language(function (c) {
      return ["\u0433\u043E\u0434\u0438\u043D\u0438", "\u0433\u043E\u0434\u0438\u043D\u0430", "\u0433\u043E\u0434\u0438\u043D\u0435"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043C\u0435\u0441\u0435\u0446\u0438", "\u043C\u0435\u0441\u0435\u0446", "\u043C\u0435\u0441\u0435\u0446\u0430"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043D\u0435\u0434\u0435\u0459\u0438", "\u043D\u0435\u0434\u0435\u0459\u0430", "\u043D\u0435\u0434\u0435\u0459\u0435"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0434\u0430\u043D\u0438", "\u0434\u0430\u043D", "\u0434\u0430\u043D\u0430"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0441\u0430\u0442\u0438", "\u0441\u0430\u0442", "\u0441\u0430\u0442\u0430"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043C\u0438\u043D\u0443\u0442\u0430", "\u043C\u0438\u043D\u0443\u0442", "\u043C\u0438\u043D\u0443\u0442\u0430"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0441\u0435\u043A\u0443\u043D\u0434\u0438", "\u0441\u0435\u043A\u0443\u043D\u0434\u0430", "\u0441\u0435\u043A\u0443\u043D\u0434\u0435"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043C\u0438\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434\u0438", "\u043C\u0438\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434\u0430", "\u043C\u0438\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434\u0435"][getSlavicForm(c)];
    }, ","),
    ta: language(function (c) {
      return c === 1 ? "\u0BB5\u0BB0\u0BC1\u0B9F\u0BAE\u0BCD" : "\u0B86\u0BA3\u0BCD\u0B9F\u0BC1\u0B95\u0BB3\u0BCD";
    }, function (c) {
      return c === 1 ? "\u0BAE\u0BBE\u0BA4\u0BAE\u0BCD" : "\u0BAE\u0BBE\u0BA4\u0B99\u0BCD\u0B95\u0BB3\u0BCD";
    }, function (c) {
      return c === 1 ? "\u0BB5\u0BBE\u0BB0\u0BAE\u0BCD" : "\u0BB5\u0BBE\u0BB0\u0B99\u0BCD\u0B95\u0BB3\u0BCD";
    }, function (c) {
      return c === 1 ? "\u0BA8\u0BBE\u0BB3\u0BCD" : "\u0BA8\u0BBE\u0B9F\u0BCD\u0B95\u0BB3\u0BCD";
    }, function (c) {
      return c === 1 ? "\u0BAE\u0BA3\u0BBF" : "\u0BAE\u0BA3\u0BBF\u0BA8\u0BC7\u0BB0\u0BAE\u0BCD";
    }, function (c) {
      return "\u0BA8\u0BBF\u0BAE\u0BBF\u0B9F" + (c === 1 ? "\u0BAE\u0BCD" : "\u0B99\u0BCD\u0B95\u0BB3\u0BCD");
    }, function (c) {
      return "\u0BB5\u0BBF\u0BA9\u0BBE\u0B9F\u0BBF" + (c === 1 ? "" : "\u0B95\u0BB3\u0BCD");
    }, function (c) {
      return "\u0BAE\u0BBF\u0BB2\u0BCD\u0BB2\u0BBF \u0BB5\u0BBF\u0BA8\u0BBE\u0B9F\u0BBF" + (c === 1 ? "" : "\u0B95\u0BB3\u0BCD");
    }),
    te: language(function (c) {
      return "\u0C38\u0C02\u0C35\u0C24\u0C4D\u0C38" + (c === 1 ? "\u0C30\u0C02" : "\u0C30\u0C3E\u0C32");
    }, function (c) {
      return "\u0C28\u0C46\u0C32" + (c === 1 ? "" : "\u0C32");
    }, function (c) {
      return c === 1 ? "\u0C35\u0C3E\u0C30\u0C02" : "\u0C35\u0C3E\u0C30\u0C3E\u0C32\u0C41";
    }, function (c) {
      return "\u0C30\u0C4B\u0C1C\u0C41" + (c === 1 ? "" : "\u0C32\u0C41");
    }, function (c) {
      return "\u0C17\u0C02\u0C1F" + (c === 1 ? "" : "\u0C32\u0C41");
    }, function (c) {
      return c === 1 ? "\u0C28\u0C3F\u0C2E\u0C3F\u0C37\u0C02" : "\u0C28\u0C3F\u0C2E\u0C3F\u0C37\u0C3E\u0C32\u0C41";
    }, function (c) {
      return c === 1 ? "\u0C38\u0C46\u0C15\u0C28\u0C41" : "\u0C38\u0C46\u0C15\u0C28\u0C4D\u0C32\u0C41";
    }, function (c) {
      return c === 1 ? "\u0C2E\u0C3F\u0C32\u0C4D\u0C32\u0C40\u0C38\u0C46\u0C15\u0C28\u0C4D" : "\u0C2E\u0C3F\u0C32\u0C4D\u0C32\u0C40\u0C38\u0C46\u0C15\u0C28\u0C4D\u0C32\u0C41";
    }),
    uk: language(function (c) {
      return ["\u0440\u043E\u043A\u0456\u0432", "\u0440\u0456\u043A", "\u0440\u043E\u043A\u0438"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043C\u0456\u0441\u044F\u0446\u0456\u0432", "\u043C\u0456\u0441\u044F\u0446\u044C", "\u043C\u0456\u0441\u044F\u0446\u0456"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0442\u0438\u0436\u043D\u0456\u0432", "\u0442\u0438\u0436\u0434\u0435\u043D\u044C", "\u0442\u0438\u0436\u043D\u0456"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0434\u043D\u0456\u0432", "\u0434\u0435\u043D\u044C", "\u0434\u043D\u0456"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0433\u043E\u0434\u0438\u043D", "\u0433\u043E\u0434\u0438\u043D\u0430", "\u0433\u043E\u0434\u0438\u043D\u0438"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0445\u0432\u0438\u043B\u0438\u043D", "\u0445\u0432\u0438\u043B\u0438\u043D\u0430", "\u0445\u0432\u0438\u043B\u0438\u043D\u0438"][getSlavicForm(c)];
    }, function (c) {
      return ["\u0441\u0435\u043A\u0443\u043D\u0434", "\u0441\u0435\u043A\u0443\u043D\u0434\u0430", "\u0441\u0435\u043A\u0443\u043D\u0434\u0438"][getSlavicForm(c)];
    }, function (c) {
      return ["\u043C\u0456\u043B\u0456\u0441\u0435\u043A\u0443\u043D\u0434", "\u043C\u0456\u043B\u0456\u0441\u0435\u043A\u0443\u043D\u0434\u0430", "\u043C\u0456\u043B\u0456\u0441\u0435\u043A\u0443\u043D\u0434\u0438"][getSlavicForm(c)];
    }, ","),
    ur: language("\u0633\u0627\u0644", function (c) {
      return c === 1 ? "\u0645\u06C1\u06CC\u0646\u06C1" : "\u0645\u06C1\u06CC\u0646\u06D2";
    }, function (c) {
      return c === 1 ? "\u06C1\u0641\u062A\u06C1" : "\u06C1\u0641\u062A\u06D2";
    }, "\u062F\u0646", function (c) {
      return c === 1 ? "\u06AF\u06BE\u0646\u0679\u06C1" : "\u06AF\u06BE\u0646\u0679\u06D2";
    }, "\u0645\u0646\u0679", "\u0633\u06CC\u06A9\u0646\u0688", "\u0645\u0644\u06CC \u0633\u06CC\u06A9\u0646\u0688"),
    sk: language(function (c) {
      return ["rok", "roky", "roky", "rokov"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["mesiac", "mesiace", "mesiace", "mesiacov"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["t\xFD\u017Ede\u0148", "t\xFD\u017Edne", "t\xFD\u017Edne", "t\xFD\u017Ed\u0148ov"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["de\u0148", "dni", "dni", "dn\xED"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["hodina", "hodiny", "hodiny", "hod\xEDn"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["min\xFAta", "min\xFAty", "min\xFAty", "min\xFAt"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["sekunda", "sekundy", "sekundy", "sek\xFAnd"][getCzechOrSlovakForm(c)];
    }, function (c) {
      return ["milisekunda", "milisekundy", "milisekundy", "milisek\xFAnd"][getCzechOrSlovakForm(c)];
    }, ","),
    sl: language(function (c) {
      if (c % 10 === 1) {
        return "leto";
      } else if (c % 100 === 2) {
        return "leti";
      } else if (c % 100 === 3 || c % 100 === 4 || Math.floor(c) !== c && c % 100 <= 5) {
        return "leta";
      } else {
        return "let";
      }
    }, function (c) {
      if (c % 10 === 1) {
        return "mesec";
      } else if (c % 100 === 2 || Math.floor(c) !== c && c % 100 <= 5) {
        return "meseca";
      } else if (c % 10 === 3 || c % 10 === 4) {
        return "mesece";
      } else {
        return "mesecev";
      }
    }, function (c) {
      if (c % 10 === 1) {
        return "teden";
      } else if (c % 10 === 2 || Math.floor(c) !== c && c % 100 <= 4) {
        return "tedna";
      } else if (c % 10 === 3 || c % 10 === 4) {
        return "tedne";
      } else {
        return "tednov";
      }
    }, function (c) {
      return c % 100 === 1 ? "dan" : "dni";
    }, function (c) {
      if (c % 10 === 1) {
        return "ura";
      } else if (c % 100 === 2) {
        return "uri";
      } else if (c % 10 === 3 || c % 10 === 4 || Math.floor(c) !== c) {
        return "ure";
      } else {
        return "ur";
      }
    }, function (c) {
      if (c % 10 === 1) {
        return "minuta";
      } else if (c % 10 === 2) {
        return "minuti";
      } else if (c % 10 === 3 || c % 10 === 4 || Math.floor(c) !== c && c % 100 <= 4) {
        return "minute";
      } else {
        return "minut";
      }
    }, function (c) {
      if (c % 10 === 1) {
        return "sekunda";
      } else if (c % 100 === 2) {
        return "sekundi";
      } else if (c % 100 === 3 || c % 100 === 4 || Math.floor(c) !== c) {
        return "sekunde";
      } else {
        return "sekund";
      }
    }, function (c) {
      if (c % 10 === 1) {
        return "milisekunda";
      } else if (c % 100 === 2) {
        return "milisekundi";
      } else if (c % 100 === 3 || c % 100 === 4 || Math.floor(c) !== c) {
        return "milisekunde";
      } else {
        return "milisekund";
      }
    }, ","),
    sv: language("\xE5r", function (c) {
      return "m\xE5nad" + (c === 1 ? "" : "er");
    }, function (c) {
      return "veck" + (c === 1 ? "a" : "or");
    }, function (c) {
      return "dag" + (c === 1 ? "" : "ar");
    }, function (c) {
      return "timm" + (c === 1 ? "e" : "ar");
    }, function (c) {
      return "minut" + (c === 1 ? "" : "er");
    }, function (c) {
      return "sekund" + (c === 1 ? "" : "er");
    }, function (c) {
      return "millisekund" + (c === 1 ? "" : "er");
    }, ","),
    sw: assign(language(function (c) {
      return c === 1 ? "mwaka" : "miaka";
    }, function (c) {
      return c === 1 ? "mwezi" : "miezi";
    }, "wiki", function (c) {
      return c === 1 ? "siku" : "masiku";
    }, function (c) {
      return c === 1 ? "saa" : "masaa";
    }, "dakika", "sekunde", "milisekunde"), {
      _numberFirst: true
    }),
    tr: language("y\u0131l", "ay", "hafta", "g\xFCn", "saat", "dakika", "saniye", "milisaniye", ","),
    th: language("\u0E1B\u0E35", "\u0E40\u0E14\u0E37\u0E2D\u0E19", "\u0E2A\u0E31\u0E1B\u0E14\u0E32\u0E2B\u0E4C", "\u0E27\u0E31\u0E19", "\u0E0A\u0E31\u0E48\u0E27\u0E42\u0E21\u0E07", "\u0E19\u0E32\u0E17\u0E35", "\u0E27\u0E34\u0E19\u0E32\u0E17\u0E35", "\u0E21\u0E34\u0E25\u0E25\u0E34\u0E27\u0E34\u0E19\u0E32\u0E17\u0E35"),
    uz: language("yil", "oy", "hafta", "kun", "soat", "minut", "sekund", "millisekund"),
    uz_CYR: language("\u0439\u0438\u043B", "\u043E\u0439", "\u04B3\u0430\u0444\u0442\u0430", "\u043A\u0443\u043D", "\u0441\u043E\u0430\u0442", "\u043C\u0438\u043D\u0443\u0442", "\u0441\u0435\u043A\u0443\u043D\u0434", "\u043C\u0438\u043B\u043B\u0438\u0441\u0435\u043A\u0443\u043D\u0434"),
    vi: language("n\u0103m", "th\xE1ng", "tu\u1EA7n", "ng\xE0y", "gi\u1EDD", "ph\xFAt", "gi\xE2y", "mili gi\xE2y", ","),
    zh_CN: language("\u5E74", "\u4E2A\u6708", "\u5468", "\u5929", "\u5C0F\u65F6", "\u5206\u949F", "\u79D2", "\u6BEB\u79D2"),
    zh_TW: language("\u5E74", "\u500B\u6708", "\u5468", "\u5929", "\u5C0F\u6642", "\u5206\u9418", "\u79D2", "\u6BEB\u79D2")
  };

  /**
   * Helper function for creating language definitions.
   *
   * @param {Unit} y
   * @param {Unit} mo
   * @param {Unit} w
   * @param {Unit} d
   * @param {Unit} h
   * @param {Unit} m
   * @param {Unit} s
   * @param {Unit} ms
   * @param {string} [decimal]
   * @returns {Language}
   */
  function language(y, mo, w, d, h, m, s, ms, decimal) {
    /** @type {Language} */
    var result = {
      y: y,
      mo: mo,
      w: w,
      d: d,
      h: h,
      m: m,
      s: s,
      ms: ms
    };
    if (typeof decimal !== "undefined") {
      result.decimal = decimal;
    }
    return result;
  }

  /**
   * Helper function for Arabic.
   *
   * @param {number} c
   * @returns {0 | 1 | 2}
   */
  function getArabicForm(c) {
    if (c === 2) {
      return 1;
    }
    if (c > 2 && c < 11) {
      return 2;
    }
    return 0;
  }

  /**
   * Helper function for Polish.
   *
   * @param {number} c
   * @returns {0 | 1 | 2 | 3}
   */
  function getPolishForm(c) {
    if (c === 1) {
      return 0;
    }
    if (Math.floor(c) !== c) {
      return 1;
    }
    if (c % 10 >= 2 && c % 10 <= 4 && !(c % 100 > 10 && c % 100 < 20)) {
      return 2;
    }
    return 3;
  }

  /**
   * Helper function for Slavic languages.
   *
   * @param {number} c
   * @returns {0 | 1 | 2 | 3}
   */
  function getSlavicForm(c) {
    if (Math.floor(c) !== c) {
      return 2;
    }
    if (c % 100 >= 5 && c % 100 <= 20 || c % 10 >= 5 && c % 10 <= 9 || c % 10 === 0) {
      return 0;
    }
    if (c % 10 === 1) {
      return 1;
    }
    if (c > 1) {
      return 2;
    }
    return 0;
  }

  /**
   * Helper function for Czech or Slovak.
   *
   * @param {number} c
   * @returns {0 | 1 | 2 | 3}
   */
  function getCzechOrSlovakForm(c) {
    if (c === 1) {
      return 0;
    }
    if (Math.floor(c) !== c) {
      return 1;
    }
    if (c % 10 >= 2 && c % 10 <= 4 && c % 100 < 10) {
      return 2;
    }
    return 3;
  }

  /**
   * Helper function for Lithuanian.
   *
   * @param {number} c
   * @returns {0 | 1 | 2}
   */
  function getLithuanianForm(c) {
    if (c === 1 || c % 10 === 1 && c % 100 > 20) {
      return 0;
    }
    if (Math.floor(c) !== c || c % 10 >= 2 && c % 100 > 20 || c % 10 >= 2 && c % 100 < 10) {
      return 1;
    }
    return 2;
  }

  /**
   * Helper function for Latvian.
   *
   * @param {number} c
   * @returns {boolean}
   */
  function getLatvianForm(c) {
    return c % 10 === 1 && c % 100 !== 11;
  }
  function assign(destination) {
    var source;
    for (var i = 1; i < arguments.length; i++) {
      source = arguments[i];
      for (var prop in source) {
        if (has(source, prop)) {
          destination[prop] = source[prop];
        }
      }
    }
    return destination;
  }

  // We need to make sure we support browsers that don't have
  // `Array.isArray`, so we define a fallback here.
  var isArray = Array.isArray || function (arg) {
    return Object.prototype.toString.call(arg) === "[object Array]";
  };
  function has(obj, key) {
    return Object.prototype.hasOwnProperty.call(obj, key);
  }

  /**
   * @param {Pick<Required<Options>, "language" | "fallbacks" | "languages">} options
   * @throws {Error} Throws an error if language is not found.
   * @returns {Language}
   */
  function getLanguage(options) {
    var possibleLanguages = [options.language];
    if (has(options, "fallbacks")) {
      if (isArray(options.fallbacks) && options.fallbacks.length) {
        possibleLanguages = possibleLanguages.concat(options.fallbacks);
      } else {
        throw new Error("fallbacks must be an array with at least one element");
      }
    }
    for (var i = 0; i < possibleLanguages.length; i++) {
      var languageToTry = possibleLanguages[i];
      if (has(options.languages, languageToTry)) {
        return options.languages[languageToTry];
      }
      if (has(LANGUAGES, languageToTry)) {
        return LANGUAGES[languageToTry];
      }
    }
    throw new Error("No language found.");
  }

  /**
   * @param {Piece} piece
   * @param {Language} language
   * @param {Pick<Required<Options>, "decimal" | "spacer" | "maxDecimalPoints" | "digitReplacements">} options
   */
  function renderPiece(piece, language, options) {
    var unitName = piece.unitName;
    var unitCount = piece.unitCount;
    var spacer = options.spacer;
    var maxDecimalPoints = options.maxDecimalPoints;

    /** @type {string} */
    var decimal;
    if (has(options, "decimal")) {
      decimal = options.decimal;
    } else if (has(language, "decimal")) {
      decimal = language.decimal;
    } else {
      decimal = ".";
    }

    /** @type {undefined | DigitReplacements} */
    var digitReplacements;
    if ("digitReplacements" in options) {
      digitReplacements = options.digitReplacements;
    } else if ("_digitReplacements" in language) {
      digitReplacements = language._digitReplacements;
    }

    /** @type {string} */
    var formattedCount;
    var normalizedUnitCount = maxDecimalPoints === void 0 ? unitCount : Math.floor(unitCount * Math.pow(10, maxDecimalPoints)) / Math.pow(10, maxDecimalPoints);
    var countStr = normalizedUnitCount.toString();
    if (digitReplacements) {
      formattedCount = "";
      for (var i = 0; i < countStr.length; i++) {
        var char = countStr[i];
        if (char === ".") {
          formattedCount += decimal;
        } else {
          formattedCount += digitReplacements[char];
        }
      }
    } else {
      formattedCount = countStr.replace(".", decimal);
    }
    var languageWord = language[unitName];
    var word;
    if (typeof languageWord === "function") {
      word = languageWord(unitCount);
    } else {
      word = languageWord;
    }
    if (language._numberFirst) {
      return word + spacer + formattedCount;
    }
    return formattedCount + spacer + word;
  }

  /**
   * @typedef {Object} Piece
   * @prop {UnitName} unitName
   * @prop {number} unitCount
   */

  /**
   * @param {number} ms
   * @param {Pick<Required<Options>, "units" | "unitMeasures" | "largest" | "round">} options
   * @returns {Piece[]}
   */
  function getPieces(ms, options) {
    /** @type {UnitName} */
    var unitName;

    /** @type {number} */
    var i;

    /** @type {number} */
    var unitCount;

    /** @type {number} */
    var msRemaining;
    var units = options.units;
    var unitMeasures = options.unitMeasures;
    var largest = "largest" in options ? options.largest : Infinity;
    if (!units.length) return [];

    // Get the counts for each unit. Doesn't round or truncate anything.
    // For example, might create an object like `{ y: 7, m: 6, w: 0, d: 5, h: 23.99 }`.
    /** @type {Partial<Record<UnitName, number>>} */
    var unitCounts = {};
    msRemaining = ms;
    for (i = 0; i < units.length; i++) {
      unitName = units[i];
      var unitMs = unitMeasures[unitName];
      var isLast = i === units.length - 1;
      unitCount = isLast ? msRemaining / unitMs : Math.floor(msRemaining / unitMs);
      unitCounts[unitName] = unitCount;
      msRemaining -= unitCount * unitMs;
    }
    if (options.round) {
      // Update counts based on the `largest` option.
      // For example, if `largest === 2` and `unitCount` is `{ y: 7, m: 6, w: 0, d: 5, h: 23.99 }`,
      // updates to something like `{ y: 7, m: 6.2 }`.
      var unitsRemainingBeforeRound = largest;
      for (i = 0; i < units.length; i++) {
        unitName = units[i];
        unitCount = unitCounts[unitName];
        if (unitCount === 0) continue;
        unitsRemainingBeforeRound--;

        // "Take" the rest of the units into this one.
        if (unitsRemainingBeforeRound === 0) {
          for (var j = i + 1; j < units.length; j++) {
            var smallerUnitName = units[j];
            var smallerUnitCount = unitCounts[smallerUnitName];
            unitCounts[unitName] += smallerUnitCount * unitMeasures[smallerUnitName] / unitMeasures[unitName];
            unitCounts[smallerUnitName] = 0;
          }
          break;
        }
      }

      // Round the last piece (which should be the only non-integer).
      //
      // This can be a little tricky if the last piece "bubbles up" to a larger
      // unit. For example, "3 days, 23.99 hours" should be rounded to "4 days".
      // It can also require multiple passes. For example, "6 days, 23.99 hours"
      // should become "1 week".
      for (i = units.length - 1; i >= 0; i--) {
        unitName = units[i];
        unitCount = unitCounts[unitName];
        if (unitCount === 0) continue;
        var rounded = Math.round(unitCount);
        unitCounts[unitName] = rounded;
        if (i === 0) break;
        var previousUnitName = units[i - 1];
        var previousUnitMs = unitMeasures[previousUnitName];
        var amountOfPreviousUnit = Math.floor(rounded * unitMeasures[unitName] / previousUnitMs);
        if (amountOfPreviousUnit) {
          unitCounts[previousUnitName] += amountOfPreviousUnit;
          unitCounts[unitName] = 0;
        } else {
          break;
        }
      }
    }

    /** @type {Piece[]} */
    var result = [];
    for (i = 0; i < units.length && result.length < largest; i++) {
      unitName = units[i];
      unitCount = unitCounts[unitName];
      if (unitCount) {
        result.push({
          unitName: unitName,
          unitCount: unitCount
        });
      }
    }
    return result;
  }

  /**
   * @param {Piece[]} pieces
   * @param {Pick<Required<Options>, "units" | "language" | "languages" | "fallbacks" | "delimiter" | "spacer" | "decimal" | "conjunction" | "maxDecimalPoints" | "serialComma" | "digitReplacements">} options
   * @returns {string}
   */
  function formatPieces(pieces, options) {
    var language = getLanguage(options);
    if (!pieces.length) {
      var units = options.units;
      var smallestUnitName = units[units.length - 1];
      return renderPiece({
        unitName: smallestUnitName,
        unitCount: 0
      }, language, options);
    }
    var conjunction = options.conjunction;
    var serialComma = options.serialComma;
    var delimiter;
    if (has(options, "delimiter")) {
      delimiter = options.delimiter;
    } else if (has(language, "delimiter")) {
      delimiter = language.delimiter;
    } else {
      delimiter = ", ";
    }

    /** @type {string[]} */
    var renderedPieces = [];
    for (var i = 0; i < pieces.length; i++) {
      renderedPieces.push(renderPiece(pieces[i], language, options));
    }
    if (!conjunction || pieces.length === 1) {
      return renderedPieces.join(delimiter);
    }
    if (pieces.length === 2) {
      return renderedPieces.join(conjunction);
    }
    return renderedPieces.slice(0, -1).join(delimiter) + (serialComma ? "," : "") + conjunction + renderedPieces.slice(-1);
  }

  /**
   * Create a humanizer, which lets you change the default options.
   */
  function humanizer(passedOptions) {
    var result = function humanizer(ms, humanizerOptions) {
      // Make sure we have a positive number.
      //
      // Has the nice side-effect of converting things to numbers. For example,
      // converts `"123"` and `Number(123)` to `123`.
      ms = Math.abs(ms);
      var options = assign({}, result, humanizerOptions || {});
      var pieces = getPieces(ms, options);
      return formatPieces(pieces, options);
    };
    return assign(result, {
      language: "en",
      spacer: " ",
      conjunction: "",
      serialComma: true,
      units: ["y", "mo", "w", "d", "h", "m", "s"],
      languages: {},
      round: false,
      unitMeasures: {
        y: 31557600000,
        mo: 2629800000,
        w: 604800000,
        d: 86400000,
        h: 3600000,
        m: 60000,
        s: 1000,
        ms: 1
      }
    }, passedOptions);
  }

  /**
   * Humanize a duration.
   *
   * This is a wrapper around the default humanizer.
   */
  var humanizeDuration = humanizer({});
  humanizeDuration.getSupportedLanguages = function getSupportedLanguages() {
    var result = [];
    for (var language in LANGUAGES) {
      if (has(LANGUAGES, language) && language !== "gr") {
        result.push(language);
      }
    }
    return result;
  };
  humanizeDuration.humanizer = humanizer;

  // @ts-ignore

  if (exports) {
    exports = humanizeDuration;
  } else {
    (this || _global).humanizeDuration = humanizeDuration;
  }
})();
export default exports;